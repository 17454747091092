import { Cookies } from 'react-cookie';
import { IAuthRelativePath } from '@bridge/routes/AuthRoutes';
import { generateUUID } from '@bridge/utility';
import { AppConstants } from '@bridge/constants/AppConstants';
import { CoreFactory } from '@bridge/factory/CoreFactory';

const cookie = new Cookies();
const logger = CoreFactory.getLogger();

export class WarpDriveExternalNativeAuthUrlBuilder {
  private static readonly SOFT_BLOCK_URL_BASE_LIST = [
    'connectivity.amazonworkspaces.com',
    'connectivity-gamma.amazonworkspaces.com',
  ];

  private readonly base: string;
  private redirectUrl: URL;
  private readonly isBaseUrlClientBlockerPage: boolean;

  constructor(base: string) {
    this.base = base;
    this.isBaseUrlClientBlockerPage =
      WarpDriveExternalNativeAuthUrlBuilder.isBaseUrlSoftBlockUrl(this.base);
    this.redirectUrl = this.extractRedirectUrl();
  }

  reset() {
    this.redirectUrl = new URL(this.base);
  }

  setRedirectUri(strategy: IAuthRelativePath) {
    const port = window.location.port ? `:${window.location.port}` : '';

    this.redirectUrl.searchParams.set(
      'redirect_uri',
      `https://${window.location.hostname}${port}/auth/${strategy}`
    );
  }

  setRelayStateUrl(relayStateName: string, relayStateUrl: string) {
    this.redirectUrl.searchParams.set(relayStateName, relayStateUrl);
  }

  setLocale() {
    const language = cookie.get('language');
    this.redirectUrl.searchParams.set('locale', language);
  }

  setCsrfToken() {
    const csrfToken = generateUUID();
    cookie.set(AppConstants.COOKIE_CSRF_TOKEN, csrfToken, { path: '/' });
    this.redirectUrl.searchParams.set('state', csrfToken);
  }

  getUrl() {
    if (this.isBaseUrlClientBlockerPage) {
      const actualAuthRedirectionUrlString = this.redirectUrl.toString();
      const baseUrl = new URL(this.base);
      baseUrl.searchParams.set('redirectUrl', actualAuthRedirectionUrlString);
      logger.info(`Generated auth redirection url for client block ${baseUrl}`);
      return baseUrl;
    } else {
      return this.redirectUrl;
    }
  }

  private extractRedirectUrl() {
    if (this.isBaseUrlClientBlockerPage) {
      const baseUrl = new URL(this.base);
      const redirectionUrlString = baseUrl.searchParams.get(
        'redirectUrl'
      ) as string;
      const decodedUrlString = decodeURIComponent(redirectionUrlString);
      return new URL(decodedUrlString);
    } else {
      return new URL(this.base);
    }
  }

  private static isBaseUrlSoftBlockUrl(baseUrl: string): boolean {
    let isConnectivityWebSite = false;
    WarpDriveExternalNativeAuthUrlBuilder.SOFT_BLOCK_URL_BASE_LIST.forEach(
      (connectivityUrl) => {
        if (baseUrl?.toLowerCase().includes(connectivityUrl)) {
          logger.info(
            `Identified client blocker scenario for externalAuthRedirectionUrl ${baseUrl}`
          );
          isConnectivityWebSite = true;
        }
      }
    );
    return isConnectivityWebSite;
  }
}
